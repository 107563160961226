import React from "react";
import { useState } from "react";
import axios from "../../../axiosInstance";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Image from "react-bootstrap/Image";

import "bootstrap/dist/css/bootstrap.css";

function MediaFiles() {
  const [items, setItems] = useState([]);
  const [title, setTitle] = useState("");
  const [sku, setSku] = useState("");
  const [status, setStatus] = useState("");

  const statusResetTimeout = 5000;

  const indexList = [];
  for (let i = 0; i < items.length; i++) {
    indexList.push(i + 1);
  }

  const getProductMedia = async (skip) => {
    const functionName = "getProductMedia";

    const url = `/api/productMedia/metafield/${encodeURIComponent(sku)}`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.get(url, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const { msg, item } = response.data;

        if (!skip) {
          updateStatus(msg);
        }

        if (item) {
          const { title, productMedia } = item;
          setTitle(title);

          if (productMedia) {
            const productMediaList = productMedia.list;
            setItems(productMediaList);
          }
        }
      } else {
        resetControls();
      }
    } catch (err) {
      if (err.response) {
        console.log(
          `${functionName}: err.response.data: ${JSON.stringify(
            err.response.data
          )}`
        );
        console.log(
          `${functionName}: err.response.status: ${err.response.status}`
        );
        console.log(
          `${functionName}: err.response.headers: ${err.response.headers}`
        );
      } else if (err.request) {
        console.log(`${functionName}: err.request: ${err.request}`);
      } else {
        console.log(`${functionName}: err.message: ${err.message}`);
      }

      resetControls();
    }
  };

  const skuChanged = async (sku) => {
    setSku(sku);
  };

  const search = () => getProductMedia(false);

  const reset = async () => {
    const url = `/api/productMedia/reset/${encodeURIComponent(sku)}`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.patch(url, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const { msg, item } = response.data;
        updateStatus(msg);

        if (item) {
          const { title, productMedia } = item;
          setTitle(title);

          if (productMedia) {
            const productMediaList = productMedia.list;
            setItems(productMediaList);
          }
        }
      } else {
        resetControls();
      }
    } catch (err) {
      console.log(err);
      resetControls();
    }
  };

  const activeChanged = async (id, active) => {
    const url = `/api/productMedia/update/status/${encodeURIComponent(
      sku
    )}/${id}`;

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.patch(url, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const { msg, item } = response.data;
        updateStatus(msg);

        if (item) {
          const { title, productMedia } = item;
          setTitle(title);

          if (productMedia) {
            const productMediaList = productMedia.list;
            setItems(productMediaList);
          }
        }
      } else {
        resetControls();
      }
    } catch (err) {
      console.log(err);
      resetControls();
    }
  };

  const orderChanged = async (id, order) => {
    const url = `/api/productMedia/update/order/${encodeURIComponent(
      sku
    )}/${id}`;

    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      order: order,
    };
    try {
      const response = await axios.patch(url, data, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const { msg } = response.data;
        updateStatus(msg);
      }

      getProductMedia(true);
    } catch (err) {
      console.log(err);
      resetControls();
    }
  };

  const updateTextCall = async (id, text) => {
    const url = `/api/productMedia/update/text/${encodeURIComponent(
      sku
    )}/${id}`;

    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      text: text,
    };
    try {
      const response = await axios.patch(url, data, { headers: headers });

      if (response && response.status === 200 && response.data) {
        const { msg } = response.data;
        updateStatus(msg);
      }

      getProductMedia(true);
    } catch (err) {
      console.log(err);
      resetControls();
    }
  };

  const textChanged = (id, text) => {
    const list = JSON.parse(JSON.stringify(items));
    const item = list.find((itm) => itm.id === id);

    if (item) {
      item.text = text;
      setItems(list);
    }
  };

  const updateStatus = (status) => {
    setStatus(status);

    setTimeout(function () {
      setStatus("");
    }, statusResetTimeout);
  };

  const updateText = (id) => {
    const item = items.find((itm) => itm.id === id);

    if (item) {
      updateTextCall(id, item.text);
    }
  };

  const updateTextWithNew = (id) => {
    const item = items.find((itm) => itm.id === id);

    if (item) {
      updateTextCall(id, item.newText);
    }
  };

  const resetControls = () => {
    setItems([]);
    setTitle("");
  };

  return (
    <Container className="p-2 m-0">
      <Row className="mb-2">
        <Col>
          <span className="mr-2">SKU/handle/id:</span>
          <input
            type="text"
            style={{ minWidth: 250, maxWidth: 300 }}
            onChange={(e) => skuChanged(e.target.value)}
          />
          <Button variant="primary" className="ml-2" size="lg" onClick={search}>
            Search
          </Button>
          {items && items.length > 0 && (
            <Button
              variant="primary"
              className="ml-2"
              size="lg"
              onClick={reset}
            >
              Reset
            </Button>
          )}
          <span className="ml-4" style={{ fontWeight: "bold" }}>
            {title}
          </span>
        </Col>
      </Row>
      {status && (
        <Row className="mb-2">
          <Col>
            <span>{status}</span>
          </Col>
        </Row>
      )}
      {
        <Card className="mb-2">
          <Card.Body>
            <ListGroup variant="flush">
              {items &&
                items.map((item, index) => (
                  <ListGroup.Item
                    key={index}
                    style={{ background: item.active ? "" : "#f0f0f0" }}
                  >
                    <Container>
                      <Row>
                        <Col style={{ maxWidth: 120 }}>
                          <Row>
                            <Col>
                              <label
                                style={{
                                  display: "block",
                                  paddingLeft: "15px",
                                  textIndent: "-15px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    padding: "0",
                                    margin: "0",
                                    verticalAlign: "bottom",
                                    position: "relative",
                                    top: "-3px",
                                  }}
                                  checked={item.active}
                                  onChange={(e) =>
                                    activeChanged(item.id, e.target.value)
                                  }
                                />
                                <span className="pl-2">Active</span>
                              </label>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <DropdownButton
                                id="dropdown-item-button"
                                title={item.order}
                                size="lg"
                                style={{ minWidth: 30 }}
                              >
                                {indexList &&
                                  indexList.map((itm, idx) =>
                                    item.order === idx + 1 ? (
                                      <Dropdown.Item key={idx} active>
                                        {itm}
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        key={idx}
                                        onClick={(e) =>
                                          orderChanged(item.id, itm)
                                        }
                                      >
                                        {itm}
                                      </Dropdown.Item>
                                    )
                                  )}
                              </DropdownButton>
                            </Col>
                          </Row>
                        </Col>
                        <Col style={{ maxWidth: 220 }}>
                          {item.type.toLowerCase() === "image" && (
                            <Image
                              src={item.src}
                              rounded
                              style={{ maxWidth: 220 }}
                            />
                          )}
                          {item.type.toLowerCase() === "video" && (
                            <video width="220" height="140" controls>
                              <source src={item.src} type="video/mp4" />
                            </video>
                          )}
                        </Col>
                        <Col style={{ marginLeft: 20 }}>
                          <Row>
                            <Col className="ml-2">
                              {item.newText && <span>Current:</span>}
                              <textarea
                                style={{
                                  width: "100%",
                                  height: "60px",
                                }}
                                onChange={(e) =>
                                  textChanged(item.id, e.target.value)
                                }
                                value={item.text}
                              />
                            </Col>
                          </Row>
                          {item.newText && (
                            <Row>
                              <Col className="ml-2">
                                <span>New:</span>
                                <textarea
                                  readOnly
                                  style={{
                                    width: "100%",
                                    height: "60px",
                                    background: "#f0f0f0",
                                  }}
                                  value={item.newText}
                                />
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col className="ml-2">
                              <Button
                                variant="primary"
                                className="mr-2"
                                size="lg"
                                data-id={item.id}
                                onClick={(e) => updateText(item.id)}
                              >
                                {item.newText ? "Update Current" : "Update"}
                              </Button>
                              {item.newText && (
                                <Button
                                  variant="primary"
                                  size="lg"
                                  data-id={item.id}
                                  onClick={(e) => updateTextWithNew(item.id)}
                                >
                                  Use New
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Card.Body>
        </Card>
      }
    </Container>
  );
}

export default MediaFiles;
